import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Seo from '../components/seo'

const financing = {
  context: 'Simple and Transparent',
  title: 'HVAC Financing Without Breaking the Bank',
  description:
    'To give you best financing experience we have partnered with Synchrony, one of the nation’s premier consumer financial services companies. They are the largest provider of private label credit in the United States based on purchase volume and receivables.',
  cta: 'Apply Online',
}

const Financing = () => (
  <Layout>
    <Seo title="Finance Your New HVAC System or Repairs" />

    <section className="px-4 py-12 bg-white md:px-12 lg:py-16">
      <div className="mx-auto mb-8 text-center lg:max-w-5xl">
        <h2 className="text-base font-semibold tracking-wider uppercase text-primary">{financing.context}</h2>
        <h3 className="mt-2 text-4xl font-semibold tracking-tight text-gray-900 md:text-5xl">{financing.title}</h3>
        <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">{financing.description}</p>
      </div>

      <a
        href="https://www.mysynchrony.com"
        target="_blank"
        rel="noreferrer"
        className="flex justify-center w-full mb-8"
      >
        <StaticImage
          src="../images/synchrony.svg"
          width={200}
          quality={95}
          formats={['auto', 'webp', 'avif']}
          alt="cam air ac cooling and heating"
          placeholder="none"
        />
      </a>

      <div className="flex justify-center w-full">
        <a
          href="https://www.mysynchrony.com"
          className="px-4 py-2 text-xl font-medium text-white border border-transparent rounded-md shadow-sm lg:text-2xl bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
        >
          {financing.cta}
        </a>
      </div>
    </section>
  </Layout>
)

export default Financing
